import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import LocalizedLink from '../i18n/localizedLink'
import styles from './header.module.scss'

export default class ContactPage extends React.Component {
  state = {
    isActive: true
  }

  menuClick = () => {
    this.setState(state => ({ isActive: !state.isActive }))
  }

  render() {
    const navClass = this.state.isActive ? "" : styles.active
    const menuClass = this.state.isActive ? styles.menu_btn : styles.menu_btn + " " + styles.open
    return (
      <header className={styles.header}>
        <nav className={navClass}>
          <ul>
            <li>
              <LocalizedLink to="/">
                <img alt="logo" height="55" src="/images/logo.svg" />
              </LocalizedLink>
            </li>
            <li>
              <LocalizedLink
                to="/about/"
                partiallyActive={true}
                activeClassName={styles.current}
              >
                <FormattedMessage id="about" />
              </LocalizedLink>
            </li>
            <li>
              <LocalizedLink
                to="/news/"
                partiallyActive={true}
                activeClassName={styles.current}
              >
                <FormattedMessage id="news" />
              </LocalizedLink>
            </li>
            <li className={styles.products_select}>
              <span><FormattedMessage id="products" /></span>
              <a href="https://fulmo.loquat.md" className={styles.telehealth} rel="alternate"><FormattedMessage id="fulmo" /></a>
              <LocalizedLink to="/dermatology/" className={styles.dermatologist} rel="alternate"><FormattedMessage id="loquat_dermatology" /></LocalizedLink>
            </li>
            <li>
              <LocalizedLink
                to="/blog/"
                partiallyActive={true}
                activeClassName={styles.current}
              >
                <FormattedMessage id="blog" />
              </LocalizedLink>
            </li>
            <li>
              <OutboundLink href="https://forms.office.com/Pages/ResponsePage.aspx?id=R_qjPig9a06lz1ksEUkZStJvDEy_iu1LuxDsq1bAGPZUMzNVS1ZKWFY4MkZNRzExRUNWU1pJV1g0UCQlQCN0PWcu">
                <FormattedMessage id="contact" />
              </OutboundLink>
            </li>
            <li className={styles.language_select}>
              <span>Language/言語</span>
              <Link to="/" className={styles.english} rel="alternate">English</Link>
              <Link to="/ja/" className={styles.japanese} rel="alternate">日本語</Link>
            </li>
          </ul>
        </nav>
        <span role="button" className={menuClass} onClick={this.menuClick}>
          <span></span>
        </span>
      </header>
    )
  }
}
