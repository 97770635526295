module.exports = {
    ja: {
        path: 'ja',
        localeLanguage: 'ja-JP',
        countryCode: 'jp',
        default: false
    },
    en: {
        path: 'en',
        localeLanguage: 'en-US',
        countryCode: 'us',
        default: true
    }
}