import LocalizedLink from '../i18n/localizedLink'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import styles from './footer.module.scss'

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.wrapper}>
      <section>
        <h3 className={styles.company}> Loquat Inc. </h3>
        <ul className={styles.company}>
          <li>
            <LocalizedLink
              to="/about/"
              partiallyActive={true}
              activeClassName={styles.current}
            >
              <FormattedMessage id="about" />
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink
              to="/news/"
              partiallyActive={true}
              activeClassName={styles.current}
            >
              <FormattedMessage id="news" />
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink
              to="/blog/"
              partiallyActive={true}
              activeClassName={styles.current}
            >
              <FormattedMessage id="blog" />
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink
              to="/faq/"
              partiallyActive={true}
              activeClassName={styles.current}
            >
              <FormattedMessage id="faq" />
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink
              to="/contact/"
              partiallyActive={true}
              activeClassName={styles.current}
            >
              <FormattedMessage id="contact" />
            </LocalizedLink>
          </li>
        </ul>
      </section>
      <section>
        <h3 className={styles.about}>
          <FormattedMessage id="terms" />
        </h3>
        <ul className={styles.about}>
          <li>
            <LocalizedLink
              to="/termsofuse/"
              partiallyActive={true}
              activeClassName={styles.current}
            >
              <FormattedMessage id="termsofuse" />
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink
              to="/privacypolicy/"
              partiallyActive={true}
              activeClassName={styles.current}
            >
              <FormattedMessage id="privacypolicy" />
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink
              to="/specifiedcommercialtransactionslaw/"
              partiallyActive={true}
              activeClassName={styles.current}
            >
              <FormattedMessage id="specifiedcommercialtransactionslaw" />
            </LocalizedLink>
          </li>
        </ul>
      </section>
      <section>
        <h3 className={styles.follow}>
          <FormattedMessage id="socialaccount" />
        </h3>
        <ul className={styles.follow}>
          <li>
            <OutboundLink href="https://twitter.com/loquatmedical">
              Twitter
            </OutboundLink>
          </li>
        </ul>
      </section>
      <section>
        <h3 className={styles.language}>言語/Language</h3>
        <ul className={styles.language}>
          <li>
            <a href="/" rel="alternate">
              English
            </a>
          </li>
          <li>
            <a aria-current="page" href="/ja/" rel="alternate">
              日本語
            </a>
          </li>
        </ul>
      </section>
      <p className={styles.copyright}>&copy;2016 - 2021 Loquat Inc.</p>
    </div>
  </footer>
)

export default Footer
