import React from 'react'
import { IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'

import messages from '../i18n/message.json'

import Header from './header'
import Footer from './footer'
import '../styles/style.scss'

const Layout = ({ locale, children }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <>
      <Header locale={locale} />
      {children}
      <Footer locale={locale} />
    </>
  </IntlProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
